import React, { useState } from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"

const DropdownMenu = ({ anchor, children, path }) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <Item
      onMouseDown={() => setShowMenu(true)}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <a>{anchor}</a>
      {showMenu && <Dropdown>{children}</Dropdown>}
    </Item>
  )
}

const Item = styled.div`
  /* position: relative; */
  margin-top: 1px;
  cursor: default;
  padding: 1rem 0;
`

const Dropdown = styled.div`
  margin-left: 0;
  margin-top: 1rem;
  text-align: left;
  background: ${DesignTokens.colors.white};
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid ${DesignTokens.colors.primary[500]};
  & > li {
    display: inline-flex;
    margin: 0;
    a {
      text-align: left;
      width: 100%;
      padding: ${DesignTokens.spacing[2]} ${DesignTokens.spacing[4]};
      color: ${DesignTokens.colors.primary[500]};
      text-decoration: none;
      &:hover {
        color: white;
        background-color: ${DesignTokens.colors.primary[500]};
      }
    }
  }
`

export default DropdownMenu
