import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"

const VerifiedHeader = () => (
  <Wrapper data-datocms-noindex>
    <Container>
      <Row>
        <Col md={12}>
          Il tuo account è in attesa di verifica
        </Col>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  padding: 0.75rem 0;
  text-transform: uppercase;
  background-color: #ffcc00;
  font-size: 0.85rem;
  text-align: center;
  font-weight: bold;
`

export default VerifiedHeader
