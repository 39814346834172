import React, { useEffect } from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import { Link } from "gatsby"
import Gut from "./gut"
import { Instagram, Send, Youtube } from "react-feather"
import ExternalLink from "./externalLink"
import ria from "../images/ria.jpg"

const Footer = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://cdn.iubenda.com/iubenda.js"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Wrapper data-datocms-noindex>
      <Container>
        <CustomRow justify={"between"}>
          <Col md={4}>
            <Gut gutter={1.5}>
              <Company>Editrice San Marco</Company>
              <Details>
                Via Pontesecco 9/bis
                <br />
                24010 Ponteranica - Bergamo
                <br />
                <a href="tel:0354128249">
                  T. 035 4128249
                </a>
                <br />
                <a href="mailto:info@editricesanmarco.it">
                  info@editricesanmarco.it
                </a>
                <br />
                P.IVA 00214300162
              </Details>
            </Gut>
            <Row style={{ marginTop: "2rem" }}>
              <Col>
                <Gut gutter={0.5}>
                  <Details>L’Editrice San Marco è certificata da:</Details>
                  <Link to="/politica-per-la-qualita/">
                    <img src={ria} width="140" />
                  </Link>
                </Gut>
              </Col>
            </Row>
          </Col>
          <Col md={"content"}>
            <Links>
              <Link to="/politica-per-la-qualita">Politica per la qualità</Link>
              <Link to="/codice-etico/">Codice etico</Link>
              <Link to="/codice-di-autoregolamentazione-del-settore-editoriale-educativo">
                Codice di autoregolamentazione
              </Link>
              <Link to="/codice-di-autoregolamentazione-polite">
                Codice di autoregolamentazione POLITE
              </Link>
              <a
                href="https://www.iubenda.com/privacy-policy/77795378"
                className="iubenda-nostyle no-brand iubenda-embed"
                title="Privacy Policy"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.iubenda.com/privacy-policy/77795378/cookie-policy"
                className="iubenda-nostyle no-brand iubenda-embed"
                title="Cookie Policy"
              >
                Cookie Policy
              </a>
            </Links>
          </Col>
          <Col md={"content"}>
            <Row>
              <Col>
                <YoutubeLink>
                  Seguici su:
                  <ExternalLink
                    href="https://www.youtube.com/channel/UCXF1rU7z3bDjfQBiZtko3TA"
                    rel="nofollow noopener"
                  >
                    <Youtube size={32} /> <span>Youtube</span>
                  </ExternalLink>
                  <ExternalLink
                    href="https://t.me/didatticasanmarco"
                    rel="nofollow noopener"
                  >
                    <Send size={32} /> <span>Telegram</span>
                  </ExternalLink>
                  <ExternalLink
                    href="https://www.instagram.com/editricesanmarco/"
                    rel="nofollow noopener"
                  >
                    <Instagram size={32} /> <span>Instagram</span>
                  </ExternalLink>
                </YoutubeLink>
              </Col>
            </Row>
          </Col>
        </CustomRow>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 6rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  color: white;
  background-color: ${DesignTokens.colors.primary[500]};
  a {
    color: white;
  }
  @media screen and (max-width: 768px) {
    margin-top: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`

const CustomRow = styled(Row)`
  @media screen and (max-width: 768px) {
    & > * {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Company = styled.p`
  font-family: "Source Code Pro";
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  letter-spacing: 3px;
`

const Details = styled.p`
  line-height: 1.4;
  font-size: 0.75rem;
  letter-spacing: 3px;
`

const Links = styled.div`
  font-family: "Source Code Pro";
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
  & > * {
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const YoutubeLink = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  a {
    display: flex;
    flex-direction: row;
    & > * {
      margin-right: 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export default Footer
