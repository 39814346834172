/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Helmet from "react-helmet"
import TopHeader from "./topHeader"
import Footer from "./footer"
import MobileHeader from "./mobileHeader"
import styled from "styled-components"
import VerifiedHeader from "./verifiedBanner"
import { UserContext } from "../context/userContext"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&family=Source+Sans+Pro:wght@400;700&family=Poppins:wght@400;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <UserContext.Consumer>
        {user => {
          if (user && user.user) {
            return user.user.isVerified !== true ? <VerifiedHeader /> : null
          }
          return null
        }}
      </UserContext.Consumer>
      <TopHeader />
      <IsDesktop>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      </IsDesktop>
      <IsMobile>
        <MobileHeader siteTitle={data.site.siteMetadata?.title || `Title`} />
      </IsMobile>
      <main>{children}</main>
      <Footer />
    </>
  )
}

const IsDesktop = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
  }
`

const IsMobile = styled.div`
  display: block;
  @media screen and (min-width: 769px) {
    display: none;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
