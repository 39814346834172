import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { Col, Container, Row } from "react-grid-system"
import logo from "../images/logo.svg"
import DesignTokens from "./designTokens"
import DropdownMenu from "./dropdownMenu"
import { X, Menu } from "react-feather"
import { Body } from "./typography"

const MobileHeader = ({ siteTitle }) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <Wrapper data-datocms-noindex>
      <Container>
        <Row>
          <Col>
            <Row justify="between">
              <Col xs={5}>
                <Link to="/">
                  <img src={logo} />
                </Link>
              </Col>
              <Col xs={"content"}>
                <Button
                  role="button"
                  tabIndex={0}
                  onClick={() => setShowMenu(!showMenu)}
                  onKeyDown={() => setShowMenu(!showMenu)}
                >
                  <Menu />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <AnimatePresence>
        {showMenu && (
          <StyledOverlay
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.15 }}
            exit={{ opacity: 0 }}
          >
            <header>
              <X
                color={DesignTokens.colors.primary[500]}
                size={32}
                onClick={() => setShowMenu(false)}
              />
            </header>
            <Links>
              <Link to="/la-casa-editrice/" activeClassName="active">
                La casa editrice
              </Link>
              <LinkGroup>
                <Body>Catalogo</Body>
                <Link
                  to="/scuola-secondaria-di-secondo-grado/"
                  activeClassName="active"
                >
                  Scuola secondaria di secondo grado
                </Link>
                <Link
                  to="/istruzione-e-formazione-professionale/"
                  activeClassName="active"
                >
                  Istruzione e formazione professionale
                </Link>
              </LinkGroup>
              <LinkGroup>
                <Body>Rete commerciale</Body>
                <Link to="/rete-di-propaganda/" activeClassName="active">
                  Rete di propaganda
                </Link>
                <Link to="/rete-di-distribuzione/" activeClassName="active">
                  Rete di distribuzione
                </Link>
              </LinkGroup>
              <Link to="/news/" activeClassName="active">
                News
              </Link>
              <Link to="/faq/" activeClassName="active">
                Faq
              </Link>
              <Link to="/contatti/" activeClassName="active">
                Contatti
              </Link>
            </Links>
          </StyledOverlay>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-family: "Source Code Pro";
    text-transform: uppercase;
    color: ${DesignTokens.colors.primary[500]};
  }
`

const Wrapper = styled.header`
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #979797;
`

const Links = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  a {
    font-family: "Source Code Pro";
    color: ${DesignTokens.colors.primary[500]};
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    text-decoration: none;
    &:hover,
    &.active {
      text-decoration: underline;
    }
  }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70px;
`

const StyledOverlay = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: ${DesignTokens.colors.white};
  z-index: 100000;
  header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    text-align: right;
    padding: 0 1.2rem;
    color: ${DesignTokens.colors.primary};
  }
  & > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    & > li {
      font-size: ${DesignTokens.fontSizes[7]};
      padding: ${DesignTokens.spacing[3]};
      text-align: center;
      margin: 0;
      & > a {
        font-family: "Yantramanav";
        text-decoration: none;
      }
    }
  }
`

export default MobileHeader
