import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-grid-system"
import logo from "../images/logo.svg"
import DesignTokens from "./designTokens"
import DropdownMenu from "./dropdownMenu"

const Header = ({ siteTitle }) => (
  <Wrapper data-datocms-noindex>
    <Container>
      <Row>
        <Col>
          <Row justify="between">
            <Col md={3}>
              <Link to="/">
                <img src={logo} />
              </Link>
            </Col>
            <Col md={"content"}>
              <Links>
                <Link to="/la-casa-editrice/" activeClassName="active">
                  La casa editrice
                </Link>
                <DropdownMenu anchor="Catalogo">
                  <Link
                    to="/scuola-secondaria-di-secondo-grado/"
                    activeClassName="active"
                  >
                    Scuola secondaria di secondo grado
                  </Link>
                  <Link
                    to="/istruzione-e-formazione-professionale/"
                    activeClassName="active"
                  >
                    Istruzione e formazione professionale
                  </Link>
                </DropdownMenu>
                <DropdownMenu anchor="Rete commerciale">
                  <Link to="/rete-di-propaganda/" activeClassName="active">
                    Rete di propaganda
                  </Link>
                  <Link to="/rete-di-distribuzione/" activeClassName="active">
                    Rete di distribuzione
                  </Link>
                </DropdownMenu>
                <Link to="/news/" activeClassName="active">
                  News
                </Link>
                <Link to="/faq/" activeClassName="active">
                  Faq
                </Link>
                <Link to="/contatti/" activeClassName="active">
                  Contatti
                </Link>
              </Links>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.header`
  position: relative;
  z-index: 1;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #979797;
`

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  a {
    font-family: "Source Code Pro";
    color: ${DesignTokens.colors.secondary[500]};
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    @media screen and (max-width: 991px) {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }
    text-decoration: none;
    &:hover,
    &.active {
      background: ${DesignTokens.colors.primary[500]};
      color: white;
    }
  }
`

export default Header
