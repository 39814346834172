import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import auth from "../services/firebase"
import { UserContext } from "../context/userContext"
import { Link } from "gatsby"

const TopHeader = () => (
  <Wrapper data-datocms-noindex>
    <Container>
      <Row>
        <Col md={"content"}>
          Contattaci _{" "}
          <a href="mailto:info@editricesanmarco.it">
            info@editricesanmarco.it
          </a>
        </Col>
        <Col md={"content"}></Col>
        <Col>
          <Row justify="end">
            <Col md={"content"}>
              <UserContext.Consumer>
                {currentUser => (
                  <UserAccess>
                    {currentUser && currentUser.user ? (
                      <strong onClick={() => auth.signOut()}>Esci</strong>
                    ) : (
                      <Link to="/area-docenti/accedi/">Accedi</Link>
                    )}
                  </UserAccess>
                )}
              </UserContext.Consumer>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  padding: 0.75rem 0;
  color: white;
  text-transform: uppercase;
  background-color: ${DesignTokens.colors.primary[500]};
  font-size: 0.75rem;
  a {
    color: white;
  }
  @media screen and (max-width: 425px) {
    text-align: center;
  }
`

const UserAccess = styled.div`
  font-size: 1rem;
  font-weight: 700;
`

export default TopHeader
